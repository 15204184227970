<template>
  <div>
    <VisualHero
      :img="$img('/assets/img/visual/ucsc-visual-404.png')"
      :mobile-img="$img('/assets/img/visual/ucsc-visual-404-mobile.png')"
      inner-justify="center"
      img-x-position="center"
      img-y-position="top"
      :gradient="false"
    >
      <h1 class="h1 font-bold text-center text-white">Ops!</h1>
    </VisualHero>
    <div class="container min-h-32 py-24">
      <template v-if="isPropertyRoute">
        <div class="grid lg:grid-cols-3 gap-16">
          <div class="lg:col-span-1">
            <h2 class="h2 text-center font-bold mb-5 lg:text-left">
              Ci dispiace, l'immobile che hai cercato non è più disponibile.
            </h2>
            <p class="text-center text-base lg:text-left">
              Effettua una nuova ricerca, ci sono altre numerose opportunità che
              puoi trovare.
            </p>
          </div>
          <FormSearch
            class="lg:col-span-2"
            :cols="2"
            show-labels
            :fields="formSearchFields"
          />
        </div>
      </template>
      <template v-else>
        <h2 class="h2 font-bold text-center mb-5">Pagina non trovata</h2>
        <p class="text-base text-center">
          Sicuro di aver scritto l'indirizzo corretto?
        </p>
      </template>
    </div>
  </div>
</template>

<script>
import FormSearch from '~/components/FormSearch.vue'
import VisualHero from '~/components/VisualHero.vue'

export default {
  components: {
    FormSearch,
    VisualHero,
  },
  data() {
    return {
      formSearchFields: [
        'geo',
        'category',
        'typology',
        'area',
        'price',
        'room',
      ],
    }
  },

  computed: {
    isPropertyRoute() {
      return this.$route.name === 'property'
    },
  },

  mounted() {
    const tealiumPayload = { page_trigger: 'ERROR_PAGES_404' }

    this.$tealium.pageTrack(tealiumPayload, true)
  },
}
</script>
